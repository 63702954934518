import gql from "graphql-tag";

export const HeroComponentFragment = gql`
    fragment Hero on HeroItem {
        __typename
        content {
            heading
            image {
                filename
            }
            tagline
            text
            buttons
            logo
        }
    }
`;

export const CardComponentFragment = gql`
    fragment Card on CardItem {
        __typename
        content {
            heading
            image {
                filename
                alt
            }
            text
            linktext
            link {
                url
                linktype
                email
                fieldtype
            }
            visitorgroup
            component
        }
    }
`; // "target" not existiong on link?

export const NewsComponentFragment = gql`
    fragment NewsItemItem on NewsitemItem {
        __typename
        content {
            heading
            text
            date
            tags
        }
    }
`;

export const SectionComponentFragment = gql`
    fragment Section on SectionItem {
        __typename
        content {
            content1
            content2
            split
            invertOrder
            background
        }
    }
`;

export const FullSectionComponentFragment = gql`
    fragment FullSection on FullsectionItem {
        __typename
        content {
            content
            center
            background
        }
    }
`;

export const ShortcutItemFragment = gql`
    fragment ShortcutitemItem on ShortcutitemItem {
        content {
            link {
                url
            }
            icon {
                filename
            }
            title
            text
            label
        }
    }
`;

export const BlockQuery = gql`
    query BlockQuery($id: ID!) {
        block: ContentNode(id: $id) {
            content
            name
        }
    }
`;

export const NavigationQuery = gql`
    query NavigationQuery($slug: ID!) {
        navigation: NavigationitemItem(id: $slug) {
            content {
                linkTitle
                navigationLink {
                    id
                    fieldtype
                    email
                    cachedUrl
                    url
                    linktype
                }
                childItems
            }
        }
    }
`;

export const ContentSearchQuery = gql`
    query ContentSearchQuery($search: String!, $starts_with: String!) {
        searchResult: ContentNodes(
            starts_with: $starts_with
            filter_query: { noindex: { not_in: "true" } }
            search_term: $search
        ) {
            total
            items {
                name
                full_slug
            }
        }
    }
`;

export const SitemapQuery = gql`
    query SitemapQuery($page: Int!, $startsWith: String, $newsTag: String!) {
        pages: ContentNodes(
            starts_with: $startsWith
            per_page: 45
            page: $page
            filter_query: { noindex: { not_in: "true" } }
        ) {
            total
            items {
                full_slug
            }
        }
        news: NewsitemItems(per_page: 45, page: $page, filter_query_v2: { tags: { in: $newsTag } }) {
            total
            items {
                full_slug
            }
        }
    }
`;

export const ContentQuery = gql`
    query ContentQuery($slug: ID!, $breadcrumbSlugs: String, $footerSlug: ID!) {
        page: ContentNode(id: $slug, resolve_relations: "globalBlock.link,authors", resolve_links: "url") {
            content
            published_at
            name
        }
        breadcrumb: ContentNodes(by_slugs: $breadcrumbSlugs) {
            items {
                position
                name
                full_slug
            }
        }
        footer: FooterItem(id: $footerSlug) {
            content {
                content1
                content2
                content3
                contentBottom
            }
        }
    }
`;

export const PageQuery = gql`
    query PageQuery($slug: ID!) {
        PageItem(id: $slug) {
            id
            slug
            content {
                contentArea
                visitorgroup
            }
        }
        ArticlepageItem(id: $slug) {
            id
            content {
                body
                image {
                    id
                    filename
                }
                text
                title
                visitorgroup
            }
        }
        PageItems {
            items {
                full_slug
                position
                name
            }
        }
    }
`;

export const PartialPageQuery = gql`
    query PartialPageQuery($slug: ID!) {
        PartialpageItem(id: $slug) {
            id
            content {
                contentArea1
                contentArea2
                metaTitle
                metaDescription
            }
        }
        PageItems {
            items {
                full_slug
                position
                name
            }
        }
    }
`;

export const NewsPageQuery = gql`
    query NewsPageQuery($slug: ID!) {
        NewsitemItem(id: $slug, resolve_links: "story") {
            id
            content {
                date
                heading
                tags
                intro
                text
                metaDescription
                metaTitle
                related(fields: ["intro", "date", "category", "heading"]) {
                    fullSlug
                    content
                }
                authors {
                    content
                }
            }
        }
    }
`;

export const NewsListQuery = gql`
    query NewsListQuery($per_page: Int, $page: Int, $tag: [String], $category: String) {
        NewsitemItems(
            per_page: $per_page
            page: $page
            filter_query_v2: { tags: { in_array: $tag }, category: { in: $category } }
            sort_by: "content.date:desc"
        ) {
            total
            items {
                id
                full_slug
                content {
                    category
                    heading
                    image {
                        filename
                        alt
                    }
                    intro
                    date
                }
            }
        }
    }
`;

export const RelatedNewsQuery = gql`
    query RelatedNewsQuery($relatedNewsGuids: String) {
        NewsitemItems(by_uuids: $relatedNewsGuids) {
            items {
                name
                full_slug
                content {
                    date
                    intro
                    tags
                    category
                }
            }
        }
    }
`;
