"use client";
import * as React from "react";
import { useRef, useState } from "react";
import * as ClientCookies from "js-cookie";
import { typeGuard } from "movestic-ui/types/utilTypes";

export interface CookieConsentContextProps {
    allowMarketingCookies: boolean;
    setAllowMarketingCookies: (value: boolean) => void;
    allowAnalyzeCookies: boolean;
    setAllowAnalyzeCookies: (value: boolean) => void;
    showPopup: boolean;
    initCookieConsent: () => void;
    disposeCookieConsent: () => void;
    saveCookieSettings: (settings: { allowMarketingCookies: boolean; allowAnalyzeCookies: boolean }) => void;
    view: "intro" | "settings";
    gotoView: (view: CookieConsentContextProps["view"]) => void;
}

// Theese names are bound to GTM/GA
export type CookieConsentData = {
    ad_storage: "denied" | "granted";
    analytics_storage: "denied" | "granted";
};

export const SHOW_COOKIE_CONSENT_POSTMESSAGE_CONTENT = "showCookieConsent";
const COOKIE_NAME = "cookie_consent";
const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_COOKIE_DOMAIN;

const CookieConsentContext = React.createContext<CookieConsentContextProps | undefined>(undefined);

interface OwnProps {
    children: React.ReactNode;
}

export const CookieConsentProvider: React.FunctionComponent<OwnProps> = ({ children }) => {
    const [showPopup, _setShowPopup] = useState(false);
    const [allowMarketingCookies, setAllowMarketingCookies] = useState(false);
    const [allowAnalyzeCookies, setAllowAnalyzeCookies] = useState(false);
    const [view, setView] = useState<CookieConsentContextProps["view"]>("intro");

    const showPopupRef = useRef(showPopup);

    const setShowPopup = (value: boolean) => {
        showPopupRef.current = value;
        _setShowPopup(value);
    };

    const initCookieConsent = () => {
        window.addEventListener("message", (e) => handleIncomingMessage(e));
        const data = getCookieConsentData();

        if (!isCookieConsent(data)) {
            setShowPopup(true);
        }

        setAllowMarketingCookies(data?.ad_storage === "granted");
        setAllowAnalyzeCookies(data?.analytics_storage === "granted");

        addGTM(/*data*/);
    };

    const disposeCookieConsent = () => {
        window.removeEventListener("message", (e) => handleIncomingMessage(e));
    };

    const handleIncomingMessage = (e: MessageEvent) => {
        if (e.data === SHOW_COOKIE_CONSENT_POSTMESSAGE_CONTENT && !showPopupRef.current) {
            setShowPopup(true);
            setView("intro");
        }
    };

    const saveCookieSettings = (settings: { allowMarketingCookies: boolean; allowAnalyzeCookies: boolean }) => {
        setCookieConsentData({
            ad_storage: settings.allowMarketingCookies ? "granted" : "denied",
            analytics_storage: settings.allowAnalyzeCookies ? "granted" : "denied",
        });

        setShowPopup(false);
    };

    return (
        <CookieConsentContext.Provider
            value={{
                showPopup,
                initCookieConsent,
                disposeCookieConsent,
                allowMarketingCookies,
                allowAnalyzeCookies,
                setAllowMarketingCookies,
                setAllowAnalyzeCookies,
                saveCookieSettings,
                view,
                gotoView: setView,
            }}
        >
            {children}
        </CookieConsentContext.Provider>
    );
};

export const useCookieConsentContext = () => React.useContext(CookieConsentContext);
export const CookieConsentConsumer = CookieConsentContext.Consumer;

const isCookieConsent = typeGuard((data: CookieConsentData) =>
    data && typeof data === "object" && "ad_storage" in data && "analytics_storage" in data
        ? (data as CookieConsentData)
        : undefined,
);

const setCookieConsentData = (data: CookieConsentData) => {
    //addGTM(data);

    //@ts-ignore
    window.dataLayer.push({
        event: "cookie_update",
        consent_storage: {
            ad_storage: data.ad_storage,
            analytics_storage: data.analytics_storage,
        },
    });

    if (isCookieConsent(data)) {
        ClientCookies.set(COOKIE_NAME, data, {
            expires: new Date(new Date().getFullYear() + 10, 0, 1),
            domain: COOKIE_DOMAIN,
            secure: true,
            sameSite: "Strict",
        });
    }
};

const getCookieConsentData = () => {
    const cookieConsent = ClientCookies.getJSON(COOKIE_NAME);

    if (isCookieConsent(cookieConsent)) {
        return cookieConsent;
    }

    return null;
};

export const getAdStorageCookieValue = () => {
    const cookieConsent = ClientCookies.getJSON(COOKIE_NAME);
    return cookieConsent;
};

const defaultConsent: CookieConsentData = {
    ad_storage: "denied",
    analytics_storage: "denied",
};

const addGTM = (/*data: CookieConsentData*/) => {
    const gtmScript = document.querySelector("script[src^='https://www.googletagmanager.com/gtm.js']");
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];

    // function gtag() {
    //     // @ts-ignore
    //     // window.dataLayer.push(arguments);
    // }

    // if (data) {
    //     // @ts-ignore
    //     gtag("consent", "default", data);
    // } else {
    //     // @ts-ignore
    //     window.dataLayer.push({
    //         event: "cookie_update",
    //         consent_storage: {
    //             ad_storage: defaultConsent.ad_storage,
    //             analytics_storage: defaultConsent.analytics_storage,
    //         },
    //     });
    // }

    if (gtmScript) {
        return;
    }

    /* eslint-disable one-var, no-var, eqeqeq */
    ((w, d, s, l, i) => {
        w[l] = w[l] || [];
        w[l].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s) as HTMLScriptElement,
            dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", process.env.NEXT_PUBLIC_GTM_ID);
    /* eslint-enable one-var, no-var, eqeqeq */
};
