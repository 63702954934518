"use client";
import mainTheme from "movestic-ui/main.theme";
import React from "react";
import * as FormatHelper from "movestic-ui/utils/formatHelpers";
import { calculateTotalSavings } from "movestic-ui/utils/compoundIntrest";
import { H2 } from "./Texts";
import { ArrowRight } from "./icons/ArrowRight";
import { PrimaryButton } from "./Buttons";
import { Slider } from "./Slider";
import { Input } from "./Input";
import { SadFace } from "./icons/SadFace";
import { HappyFace } from "./icons/HappyFace";
import { QuestionMark } from "./icons/QuestionMark";
import { LightbulbShine } from "./icons/LightbulbShine";
import { cn } from "../utils/tailwind-utils";
import { InfoDialog } from "./InfoDialog";
import { trackEvent, EventType } from "movestic-blocks/utils/gtmHandler";
import { GenericObject } from "movestic-ui/types/gtmTypes";

const EMPLOYER_FEE_YIELD = 1.06;
const INTREST_RATE = EMPLOYER_FEE_YIELD * 100 - 100;
const MIN_AGE = 25;
const MAX_AGE = 67;
const MIN_SALARY = 0;
const MAX_SALARY = 150_000;
const MIN_TAXAMOUNT = 0;
const MAX_TAXAMOUNT = 20_000;
const MIN_PENSIONAGE = 55;
const MAX_PENSIONAGE = 85;
const SALARY_THRESHOLD = 54_200;
const MIN_SUITABLE_AMOUNT = 1_000;

export const SalaryTaxExchange: React.FunctionComponent = () => {
    const [monthlySalaryValue, setMonthlySalaryValue] = React.useState(FormatHelper.formatNumber(MIN_SALARY, 0, 0));
    const [taxExchangeAmountValue, setTaxExchangeAmountValue] = React.useState(
        FormatHelper.formatNumber(MIN_SALARY, 0, 0),
    );
    const monthlySalary = parseInt(monthlySalaryValue.replace(/[^0-9]/g, ""));
    const taxExchangeAmount = parseInt(taxExchangeAmountValue.replace(/[^0-9]/g, ""));
    const [yourAge, setYourAge] = React.useState<number>(40);
    const [pensionAge, setPensionAge] = React.useState<number>(67);
    const [view, setView] = React.useState<"initial" | "summary">("initial");

    const taxExchangeActualAmount = Math.round(taxExchangeAmount * EMPLOYER_FEE_YIELD);
    const taxExchangeAmountCeiling = Math.max(1000, monthlySalary - SALARY_THRESHOLD);
    const isEligable = monthlySalary >= SALARY_THRESHOLD;
    const isSuitable = monthlySalary - SALARY_THRESHOLD > MIN_SUITABLE_AMOUNT;
    const isInitialView = view === "initial";
    const validTaxExchangeAmount = taxExchangeAmount <= taxExchangeAmountCeiling;
    const yearsValue = pensionAge - yourAge;
    const totalSavings =
        yearsValue > 0 && calculateTotalSavings(taxExchangeActualAmount, INTREST_RATE, pensionAge - yourAge);

    const hasError = !validTaxExchangeAmount && isEligable && isSuitable;

    const monthlySalaryChange = (val: string) => {
        // This is needed to support thousand seperated value, empty value and "0" in the slider
        const intVal = parseInt(String(val).replace(/[^0-9]/g, ""));

        if (isNaN(intVal)) {
            setMonthlySalaryValue("");
        } else if (intVal >= MIN_SALARY && intVal <= MAX_SALARY) {
            setMonthlySalaryValue(FormatHelper.formatNumber(intVal, 0, 0));
        }
    };

    const taxExchangeChange = (val: string) => {
        const intVal = parseInt(String(val).replace(/[^0-9]/g, ""));

        if (isNaN(intVal)) {
            setTaxExchangeAmountValue("");
        } else if (intVal >= MIN_TAXAMOUNT && intVal <= MAX_TAXAMOUNT) {
            setTaxExchangeAmountValue(FormatHelper.formatNumber(intVal, 0, 0));
        }
    };

    const continueForm = () => {
        setView("summary");
        trackEvent(EventType.SalaryTax, {
            textValue: `Continue click`,
        } as GenericObject);
    };

    const styledMessageBox = "p-8 rounded-lg bg-brightGray flex flex-col gap-4 items-center text-center";

    return (
        <div className="@container/salarytax">
            <div className="grid grid-cols-1 gap-6 @2xl/salarytax:grid-cols-2">
                <div className="p-8 rounded-lg shadow ">
                    {isInitialView ? (
                        <>
                            <H2>Öka din framtida pension med löneväxling</H2>
                            <p>
                                Löneväxling innebär att du sparar extra till pensionen genom att avsätta en del av din
                                bruttolön till ett sparande.{" "}
                            </p>
                            <p>Börja här för att ta reda på om detta är ett bra sätt för dig att spara.</p>
                            <br />
                            <div className="flex flex-col gap-8">
                                <div className="flex justify-between">
                                    <div className="font-bold ">Ange din månadslön, före skatt</div>
                                    <div>
                                        <Input
                                            value={monthlySalaryValue}
                                            onChange={(e) => monthlySalaryChange(e.target.value)}
                                            className="w-24"
                                        />{" "}
                                        kr
                                    </div>
                                </div>
                                <Slider
                                    min={MIN_SALARY}
                                    step={100}
                                    max={MAX_SALARY}
                                    value={[monthlySalary]}
                                    onValueChange={(val) => monthlySalaryChange(String(val[0]))}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className="flex gap-2 mb-4 cursor-pointer align-center text-cherryDark"
                                onClick={() => setView("initial")}
                            >
                                <ArrowRight style={{ transform: "rotate(180deg)" }} />
                                <strong> Tillbaka</strong>
                            </div>
                            <H2>Hur mycket extra pension kan jag spara ihop genom löneväxling?</H2>
                            <p>
                                Fyll i dina uppgifter och se hur mycket du kan boosta ditt pensionskapital genom
                                löneväxling.
                            </p>
                        </>
                    )}
                    <div className="my-8">
                        <div className="flex flex-col gap-8">
                            <div className="flex justify-between">
                                <div className="font-bold ">Hur mycket vill du löneväxla?</div>
                                <div>
                                    {isInitialView ? (
                                        <>
                                            <Input
                                                value={taxExchangeAmountValue}
                                                onChange={(e) => taxExchangeChange(e.target.value)}
                                                className="w-24"
                                            />{" "}
                                            kr
                                        </>
                                    ) : (
                                        <div className="font-bold text-large">
                                            {FormatHelper.moneyFormatter(taxExchangeAmount, {
                                                maximumFractionDigits: 0,
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Slider
                                min={MIN_TAXAMOUNT}
                                step={100}
                                max={MAX_TAXAMOUNT}
                                value={[taxExchangeAmount]}
                                onValueChange={(val) => taxExchangeChange(String(val[0]))}
                            />
                        </div>

                        {isEligable && isSuitable && monthlySalary > MIN_SALARY && (
                            <div
                                className="flex justify-start mt-4 align-center text-small"
                                style={{ color: hasError ? mainTheme.color.cherryRed : "initial" }}
                            >
                                Du kan löneväxla upp till{" "}
                                {FormatHelper.moneyFormatter(taxExchangeAmountCeiling, { maximumFractionDigits: 0 })}
                                <InfoDialog title="Om intjänandetak">
                                    <div>
                                        Om din månadslön efter löneväxling hamnar under intjänandetaket påverkar det den
                                        nivå som socialförsäkringsförmånerna baseras på. Det innebär att du får lägre
                                        inbetalningar till den allmänna pensionen och ersättningar som föräldrapenning,
                                        sjukpenning och a-kassa sänks.
                                    </div>
                                </InfoDialog>
                            </div>
                        )}
                    </div>
                    {!isInitialView && (
                        <>
                            <div className="flex flex-col gap-8 my-4">
                                <div className="flex justify-between">
                                    <div className="flex justify-between">
                                        <div className="font-bold ">Hur gammal är du?</div>
                                    </div>
                                    <div className="font-bold text-large">{yourAge} år</div>
                                </div>
                                <Slider
                                    min={MIN_AGE}
                                    step={1}
                                    max={MAX_AGE}
                                    value={[yourAge]}
                                    onValueChange={(val) => setYourAge(val[0])}
                                />
                            </div>
                            <div className="flex flex-col gap-8 my-4 ">
                                <div className="flex justify-between">
                                    <div className="flex justify-between">
                                        <div className="font-bold ">När planerar du gå i pension?</div>
                                    </div>
                                    <div className="font-bold text-large">{pensionAge} år</div>
                                </div>
                                <Slider
                                    min={MIN_PENSIONAGE}
                                    step={1}
                                    max={MAX_PENSIONAGE}
                                    value={[pensionAge]}
                                    onValueChange={(val) => setPensionAge(val[0])}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="justify-items-center">
                    {!isInitialView && (
                        <>
                            <div className="w-full p-4 mb-4 text-center bg-white rounded-lg shadow justify-items-center">
                                <div className="p-4 mb-4 " style={{ borderBottom: "1px solid lightgray" }}>
                                    <div className="font-bold text-large">Ditt månadssparande</div>
                                    <H2 className="text-cherryRed">
                                        {FormatHelper.moneyFormatter(taxExchangeActualAmount, {
                                            maximumFractionDigits: 0,
                                        })}
                                    </H2>
                                    <div className="flex justify-center text-small">
                                        {`Inklusive ${INTREST_RATE} % som arbetsgivaren skjuter till`}
                                        <InfoDialog title="Information">
                                            <div>
                                                Arbetsgivaravgiften är lägre på pension än på lön. Det rör sig om ca 6%.
                                                Många arbetsgivare låter den anställde få den mellanskillnaden som ett
                                                tillskott till sitt sparande. Exempel: Om du löneväxlar 1000 kr i
                                                månaden och din arbetsgivare skjuter till skillnaden mellan
                                                arbetsgivaravgiften på pension och lön landar ditt pensionssparande på
                                                1060 kr i månaden.
                                            </div>
                                        </InfoDialog>
                                    </div>
                                </div>
                                <div className="p-4 mb-4 justify-items-center">
                                    <div className="font-bold text-large">
                                        Förväntat extra sparkapital när du går i pension om {yearsValue} år*
                                    </div>
                                    <H2 className="text-cherryRed">
                                        {FormatHelper.moneyFormatter(totalSavings, { maximumFractionDigits: 0 })}
                                    </H2>
                                    <div className="flex justify-center text-small">
                                        Vi har räknat med en avkastning på {INTREST_RATE} %. Viktigt att komma ihåg vid
                                        all typ sparande är att historisk avkastning inte är en garanti för framtida
                                        avkastning. En investering i fonder kan både öka och minska i värde och det är
                                        inte säkert att du får tillbaka det investerade kapitalet.
                                    </div>
                                </div>
                            </div>
                            <div className="justify-center my-4 text-small ">
                                *Räkneexemplet tar inte hänsyn till eventuella skatteeffekter eller avgifter.
                            </div>
                        </>
                    )}
                    {isInitialView && (
                        <>
                            {monthlySalary > 0 && taxExchangeAmount > 0 ? (
                                <>
                                    {isEligable && isSuitable && (
                                        <>
                                            <div className={cn(styledMessageBox, "bg-white shadow w-full")}>
                                                <HappyFace />
                                                <p>
                                                    {hasError ? (
                                                        <>
                                                            <strong>
                                                                Löneväxling är ett bra sparalternativ för dig men...
                                                            </strong>
                                                            <p>
                                                                Din månadslön efter löneväxling bör vara över{" "}
                                                                {FormatHelper.moneyFormatter(SALARY_THRESHOLD, {
                                                                    maximumFractionDigits: 0,
                                                                })}
                                                                . Du kan löneväxla upp till{" "}
                                                                <strong>
                                                                    {FormatHelper.moneyFormatter(
                                                                        taxExchangeAmountCeiling,
                                                                        {
                                                                            maximumFractionDigits: 0,
                                                                        },
                                                                    )}
                                                                </strong>{" "}
                                                                och behöver välja ett lägre belopp för att inte påverka
                                                                ersättningar från olika socialförsäkringar, till exempel
                                                                sjuk- eller föräldrapenning, eftersom dessa baseras på
                                                                din utbetalda lön.
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <strong>
                                                                Löneväxling är ett bra sparalternativ för dig
                                                            </strong>
                                                            <p>
                                                                Prata med din arbetsgivare för att ta reda på om de
                                                                erbjuder löneväxling.
                                                            </p>
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                            {validTaxExchangeAmount && (
                                                <PrimaryButton
                                                    style={{ margin: "1rem 0", alignSelf: "center" }}
                                                    onClick={() => continueForm()}
                                                >
                                                    Beräkna vad din löneväxling blir{" "}
                                                    <ArrowRight
                                                        color={mainTheme.color.white}
                                                        style={{ marginLeft: "0.5rem" }}
                                                    />
                                                </PrimaryButton>
                                            )}
                                        </>
                                    )}
                                    {(!isEligable || !isSuitable) && monthlySalary > MIN_SALARY && (
                                        <>
                                            <div className={cn(styledMessageBox, "bg-white shadow w-full")}>
                                                <SadFace />
                                                <p>
                                                    <strong>Löneväxling är inte ett bra sparalternativ för dig</strong>
                                                    <p>
                                                        Du bör ha en månadslön på minst{" "}
                                                        {FormatHelper.moneyFormatter(SALARY_THRESHOLD, {
                                                            maximumFractionDigits: 0,
                                                        })}{" "}
                                                        kvar efter löneväxling för att det ska vara lönsamt att
                                                        löneväxla.
                                                    </p>
                                                    <p>
                                                        Om din lön är lägre kan löneväxlingen innebära att du får lägre
                                                        ersättning ur olika socialförsäkringar, till exempel sjuk- eller
                                                        föräldrapenning, eftersom dessa baseras på din bruttoinkomst.
                                                    </p>
                                                </p>
                                            </div>
                                            <div className={cn(styledMessageBox, "p-4 mt-4 flex-row")}>
                                                <div className="self-start">
                                                    <LightbulbShine />
                                                </div>
                                                <div className="text-left ">
                                                    Intresserad av ett annat sparalternativ? I så fall kan
                                                    kapitalförsäkring vara något för dig.{" "}
                                                    <a href="https://www.movestic.se/privat/sparande/kapitalforsakring/">
                                                        Läs om kapitalförsäkring
                                                    </a>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className={cn(styledMessageBox, "bg-white shadow w-full")}>
                                    <QuestionMark />
                                    <strong>Är löneväxling något för dig?</strong>
                                    <p>
                                        Här kommer du få se om löneväxling är ett bra sparalternativ för dig. Börja med
                                        att ange din månadslön och hur mycket du tänker löneväxla.
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                    {!isInitialView && (
                        <>
                            <div className={styledMessageBox}>
                                <strong>Vill du börja löneväxla?</strong>
                                <p>
                                    Prata med din arbetsgivare för att ta reda på om de erbjuder löneväxling och hur
                                    upplägget ser ut. Kom också ihåg att din arbetsgivare kan ha en begränsning för hur
                                    stort belopp du kan löneväxla.
                                </p>
                            </div>
                            <div className={cn(styledMessageBox, "p-4 mt-4 flex-row")}>
                                <div className="self-start">
                                    <LightbulbShine />
                                </div>
                                <div className="text-left ">
                                    Erbjuder inte din arbetsgivare löneväxling? I så fall kan kapitalförsäkring vara
                                    något för dig.{" "}
                                    <a href="https://www.movestic.se/privat/sparande/kapitalforsakring/">
                                        Läs om kapitalförsäkring
                                    </a>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
