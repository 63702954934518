"use client";
import MenuItemLink from "movestic-ui/types/menu/MenuItemLink";
import Link from "next/link";
import { getStructuredDataBreadcrumbs } from "movestic-blocks/utils/structuredData";
import Script from "next/script";
import React from "react";

export const Breadcrumb: React.FunctionComponent<{
    navigation: MenuItemLink[];
    className?: string;
}> = ({ navigation, className }) => {
    const breadcrumbStructuredData = getStructuredDataBreadcrumbs(navigation);

    return (
        <div className={`flex gap-2 ${className}`}>
            <Script
                type="application/ld+json"
                id="structuredBreadcrumbList"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(breadcrumbStructuredData),
                }}
            />
            {navigation?.map((nav, index) =>
                index !== navigation.length - 1 ? (
                    <div key={nav.text} className="flex gap-2">
                        <Link href={nav.url} key={index} className="text-cherryDark hover:text-cherryRed">
                            {nav.text}
                        </Link>
                        {index !== navigation.length - 1 && <div>›</div>}
                    </div>
                ) : (
                    <div key={nav.text} className="hidden md:block">
                        {nav.text}
                    </div>
                ),
            )}
        </div>
    );
};
